<template>
  <div class="home">
    <div class="filters">
      <div>
        <label>岗位</label>
        <ul>
          <li
            :class="{ selected: questionData.searchForm.positionId == null }"
            @click="handlePositionChange(null)"
          >
            所有
          </li>
          <li
            v-for="position in positions"
            :key="position.id"
            :class="{ selected: questionData.searchForm.positionId === position.id }"
            @click="handlePositionChange(position.id)"
          >
            {{position.name}}
          </li>
        </ul>
      </div>
      <div>
        <label>等级</label>
        <ul>
          <li
            :class="{ selected: questionData.searchForm.positionLevel == null }"
            @click="handlePositionLevelChange(null)"
          >所有</li>
          <li
            v-for="level in $consts.POSITION_LEVELS"
            :key="level.value"
            :class="{ selected: questionData.searchForm.positionLevel === level.value }"
            @click="handlePositionLevelChange(level.value)"
          >
            {{level.name}}
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="main">
        <Ad group="index_main" mode="three" height="140px"/>
        <div class="subject">
          <div class="toolbar">
            <div class="search-box">
              <el-input placeholder="搜索" v-model="questionData.searchForm.kwd" @input="changeSearch" prefix-icon="el-icon-search"/>
            </div>
            <div class="sort">
              <SortButton :sort="status.viewsSort" @sort="val => changeSortType('views', val)">查看数</SortButton>
              <SortButton :sort="status.starSort" @sort="val => changeSortType('star', val)">收藏数</SortButton>
              <SortButton :sort="status.voteSort" @sort="val => changeSortType('vote', val)">投票数</SortButton>
              <Button type="solid" theme="function" :round="false" class="button-random">随机一道</Button>
            </div>
          </div>
          <ul class="subject-list" v-loading="status.loadingList">
            <li v-for="question in questionData.list" :key="question.id">
              <img :src="'/api' + question.tech.logoUri" alt="" class="subject-image">
              <div class="subject-info">
                <router-link :to="'/question/' + question.serialNo" target="_blank"><h3>{{question.title}}</h3></router-link>
                <ul class="tags">
                  <li><Icon type="icon-shipin"/><span>视频讲解</span></li>
                  <li>{{question.positionLevel | positionLevelText}}</li>
                  <li>{{question.tech.name}}</li>
                </ul>
                <div class="comment">
                  <img src="../assets/images/avatar.png">
                  <p>我觉得你说的对</p>
                  <span>
                    <Icon type="icon-dianzan"/>
                    <em>120</em>
                  </span>
                </div>
              </div>
              <div class="subject-data">
                <span>
                  <Icon type="icon-chakan2"/>
                  <em>{{question.readCount}}</em>
                </span>
                <span>
                  <Icon type="icon-shoucang"/>
                  <em>{{question.favoriteCount}}</em>
                </span>
                <span>
                  <Icon type="icon-zu"/>
                  <em>{{question.voteCount}}</em>
                </span>
              </div>
            </li>
          </ul>
          <div class="no-data" v-if="questionData.list.length == 0">
            <img src="../assets/images/running.gif" alt="">
            <p>站长正在努力收集中，敬请期待~</p>
          </div>
          <Pagination
            :pagination="questionData.pagination"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div class="side">
        <Ad group="index_side" mode="one" height="250px"/>
        <div class="side-block">
          <h4>热门讨论</h4>
          <ul>
            <li v-for="i in 10" :key="i" class="topic">
              <span>{{i}}</span>
              <p>你对加班有什么看法？</p>
            </li>
          </ul>
        </div>
        <div class="side-block">
          <h4>学习榜</h4>
          <ul class="learn-list">
            <li v-for="i in 5" :key="i" class="user">
              <div class="user-info">
                <img src="../assets/images/avatar.png" class="usr-avatar">
                <img v-if="i == 1" src="../assets/images/icons/person-first.png" class="medal-icon">
                <img v-else-if="i == 2" src="../assets/images/icons/person-second.png" class="medal-icon">
                <img v-else-if="i == 3" src="../assets/images/icons/person-third.png" class="medal-icon">
                <p v-else>No.{{i}}</p>
              </div>
              <div class="user-data">
                <p>奔跑的蜗牛</p>
                <UserMedals/>
                <p>题目阅读数：38</p>
                <p>练习：320 正确率：80%</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <LoginDialog/>
  </div>
</template>

<script>
import { fetchPositionList } from '@/api/basic'
import { fetchQuestionList } from '@/api/question'
import UserMedals from '@/components/common/usr/UserMedals'
import SortButton from '@/components/common/SortButton'
import Ad from '@/components/common/Ad'
import LoginDialog from '@/components/common/LoginDialog'
import { debounce } from '@/utils/utils'
export default {
  components: { LoginDialog, Ad, SortButton, UserMedals },
  data () {
    return {
      // 加载状态
      status: {
        loadingList: false,
        viewsSort: 'desc',
        starSort: null,
        voteSort: null
      },
      // 岗位列表
      positions: [],
      // 问题数据
      questionData: {
        searchForm: {
          positionId: null,
          positionLevel: null,
          kwd: ''
        },
        list: [],
        pagination: {
          total: 0,
          page: 1,
          capacity: 15
        }
      }
    }
  },
  methods: {
    // 岗位选中
    handlePositionChange (value) {
      this.questionData.searchForm.positionId = value
      this.fetchQuestionList()
    },
    // 岗位等级选中
    handlePositionLevelChange (value) {
      this.questionData.searchForm.positionLevel = value
      this.fetchQuestionList()
    },
    // 切换排序状态
    changeSortType (type, val) {
      this.status.viewsSort = null
      this.status.starSort = null
      this.status.voteSort = null
      this.status[`${type}Sort`] = val
      this.fetchQuestionList()
    },
    // 查询岗位列表
    fetchPositionList () {
      fetchPositionList({})
        .then(data => {
          this.positions = data
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 搜索
    changeSearch () {
      debounce(() => {
        console.log(1111)
      }, 1000)
    },
    // 分页
    handleCurrentChange (page) {
      this.questionData.pagination.pageIndex = page
      this.fetchQuestionList()
    },
    // 查询问题列表
    fetchQuestionList () {
      this.status.loadingList = true
      fetchQuestionList({
        ...this.questionData.pagination,
        model: this.questionData.searchForm
      })
        .then(data => {
          this.questionData.pagination.total = data.total
          this.questionData.list = data.records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(_ => {
          this.status.loadingList = false
        })
    }
  },
  created () {
    this.fetchPositionList()
    this.fetchQuestionList()
  },
  mounted () {
    document.getElementsByClassName('search-box')[0].addEventListener('input', debounce((e) => {
      this.questionData.pagination.page = 1
      this.fetchQuestionList()
    }, 600))
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/variables";
.filters {
  background: $background-color;
  padding: 20px 30px;
  border-radius: 10px;
  margin-top: 20px;
  & > div {
    display: flex;
    line-height: 40px;
    label {
      width: 50px;
      flex-shrink: 0;
      color: $font-color-second;
    }
    ul {
      flex-grow: 1;
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      li {
        padding: 0 16px;
        cursor: default;
        &:hover, &.selected {
          color: $primary-color;
        }
      }
    }
  }
}
.content {
  margin: 20px 0;
  display: flex;
  overflow: hidden;
  // 主体部分
  .main {
    flex-grow: 1;
    // 题目
    .subject {
      margin-top: 20px;
      background: $background-color;
      box-shadow: $background-shadow;
      // 搜索和排序
      .toolbar {
        display: flex;
        padding: 16px;
        position: relative;
        border-bottom: 1px solid #EFEFEF;
        .search-box {
          width: 200px;
          /deep/ .el-input .el-input__inner{
            border-radius: 20px;
          }
        }
        .sort {
          flex-grow: 1;
          display: flex;
          justify-content: right;
          align-items: center;
          padding-right: 80px;
          .button-random {
            position: absolute;
            height: 100%;
            right: 0;
          }
        }
      }
      // 题目列表
      .subject-list {
        padding: 0;
        margin: 0;
        & > li {
          display: flex;
          padding: 20px;
          .subject-image {
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-right: 10px;
          }
          .subject-info {
            flex-grow: 1;
            // 题目标题
            h3 {
              font-size: 18px;
              margin: 0;
              font-weight: normal;
            }
            // 题目标签
            .tags {
              list-style: none;
              padding: 0;
              margin: 5px 0 0 0;
              li {
                margin-right: 16px;
                display: inline-block;
                color: $font-color-third;
              }
              span {
                vertical-align: middle;
                position: relative;
                top: -1px;
              }
              i {
                margin-right: 5px;
                vertical-align: middle;
              }
            }
            // 题目评论
            .comment {
              display: inline-flex;
              background-color: $background-color-second;
              padding: 5px 10px;
              border-radius: 20px;
              margin-top: 5px;
              img {
                flex-shrink: 0;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                margin-right: 10px;
              }
              p {
                font-size: 12px;
                margin: 0 10px 0 0;
              }
              span {
                color: $font-color-third;
                flex-shrink: 0;
                i {
                  margin-right: 5px;
                }
                em {
                  font-style: normal;
                  font-size: 12px;
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
          .subject-data {
            width: 200px;
            flex-shrink: 0;
            span {
              color: $font-color-third;
              margin-right: 10px;
              i {
                margin-right: 5px;
                vertical-align: middle;
              }
              em {
                font-style: normal;
                font-size: 12px;
                vertical-align: middle;
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }
      // 暂无数据
      .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #fff;
        padding: 40px 0;
        img {
          height: 200px;
        }
        p {
          margin: 20px 0;
          color: #646363
        }
      }
    }
  }
  // 侧栏（广告区域）
  .side {
    width: 250px;
    flex-shrink: 0;
    margin-left: 10px;
    .side-block {
      margin-top: 10px;
      background-color: $background-color;
      h4 {
        padding: 15px 20px;
        margin: 0;
        font-weight: normal;
      }
      & > ul {
        list-style: none;
        padding: 0 0 20px 0;
        margin: 0;
        &.learn-list {
          padding-bottom: 0;
        }
        li {
          display: flex;
          margin-top: 15px;
          padding: 2px 20px;
          // 讨论
          &.topic {
            cursor: pointer;
            &:first-of-type {
              margin-top: 0;
            }
            &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
              span {
                background-color: $font-color-second;
              }
            }
            &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10) {
              span {
                background-color: $font-color-third;
              }
            }
            span {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 50%;
              background-color: #FF9E9E;
              text-align: center;
              color: #fff;
              margin-right: 10px;
            }
            p {
              margin: 0;
            }
          }
          // 排名用户
          &.user {
            margin-top: 0;
            padding: 10px;
            border-bottom: 1px solid $background-color-second;
            cursor: pointer;
            &:last-of-type {
              border-bottom: 0;
            }
            &:first-of-type {
              background: linear-gradient(138deg, #D9AD69 0%, #130F0A 100%);
              .user-info {
                p {
                  color: #fff;
                }
              }
              .user-data {
                p {
                  color: $font-color-forth;
                  &:first-of-type {
                    color: #fff;
                  }
                }
              }
            }
            .user-info {
              margin-right: 10px;
              .usr-avatar {
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }
              .medal-icon {
                width: 30px;
                display: block;
                margin: 0 auto;
              }
              p {
                text-align: center;
              }
            }
            .user-data {
              .user-medals {
                margin-top: 8px;
              }
              p {
                font-size: 12px;
                color: $font-color-second;
                margin: 5px 0 0 0;
                &:first-of-type {
                  font-size: 14px;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
