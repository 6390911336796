<template>
  <div class="default-layout">
    <PipaHeader/>
    <main>
      <div class="content-wrap">
        <router-view/>
      </div>
    <footer>
      <div class="content-wrap">
        <div class="logo">
          <img src="../assets/images/logo.png" class="plpa">
          <h6>©2021 枇杷村</h6>
          <p>
            <img src="../assets/images/badges.png">
            <span>湘ICP备2021010010号</span>
          </p>
        </div>
        <ul>
          <li>村口公布</li>
          <li>模拟面试</li>
          <li>模拟练习</li>
          <li>CodeRd</li>
          <li>Eva（伊娃）</li>
        </ul>
        <ul>
          <li>站长相关</li>
          <li>站长B站主页</li>
        </ul>
        <div class="qr-code">
          <img src="../assets/images/qr-code.png">
          <p>扫码进入微信群</p>
        </div>
      </div>
    </footer>
    </main>
  </div>
</template>

<script>
import PipaHeader from '@/components/common/layouts/PipaHeader'
export default {
  name: 'DefaultLayout',
  components: { PipaHeader }
}
</script>

<style scoped lang="scss">
@import "../assets/style/variables";
.default-layout {
  .content-wrap {
    width: $page-width;
    margin: 0 auto;
    // height: 100%;
  }
  // 主体
  main {
    margin-top: 62px;
    height: calc(100vh - 62px);
    overflow: auto;
  }
  // 尾部
  footer {
    margin-top: 40px;
    height: 240px;
    background: #1A1A1A;
    .content-wrap{
      display: flex;
      .logo{
        flex-shrink: 0;
        width: 310px;
        color: #BABABA;
        font-size: 12px;
        .plpa{
          opacity: 80%;
          width: 200px;
          height: 80px;
          margin: 45px 0 20px -30px;
        }
        h6{
          width: 100%;
          height: 40px;
          margin: 0;
          border-top: 1px solid #eeeeee;
          padding-top: 20px;
          box-sizing: border-box;
        }
        p{
          margin: 0 0 0 -2px;
          img{
            vertical-align: middle;
            width: 14px;
            position: relative;
            top: -2px;
            margin-right: 5px;
          }
        }
      }
      ul{
        flex-shrink: 0;
        margin: 50px 0 0 110px;
        padding: 0;
        li{
          color: #B1B1B1;
          font-size: 12px;
          margin-bottom: 15px;
          &:first-of-type{
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      .qr-code{
        flex-grow: 1;
        text-align: right;
        img{
          opacity: 80%;
          width: 130px;
          margin-top: 50px;
        }
        p{
          margin: -3px 20px 0 0;
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>
