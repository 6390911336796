<template>
  <el-pagination
    @size-change="$emit('size-change', $event)"
    @current-change="$emit('current-change', $event)"
    :hide-on-single-page="true"
    :current-page.sync="pagination.page"
    :page-size="pagination.capacity"
    layout="prev, pager, next"
    :total="pagination.total"
  >
  </el-pagination>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pagination: {}
  },
  data () {
    return {
      currentPage: 1
    }
  }
}
</script>

<style scoped lang="scss">
.el-pagination {
  display: flex;
  justify-content: right;
  position: relative;
  padding: 15px 20px;
  /deep/ .el-pagination__sizes {
    position: absolute;
    left: 20px;
  }
}
</style>
