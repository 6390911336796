import Vue from 'vue'
import Vuex from 'vuex'
import { fetchLoginUserInfo, fetchFavoriteEmojiList } from '@/api/user'
import { fetchEmojiList } from '@/api/basic'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录用户信息
    userInfo: null,
    // 当前选中菜单
    menuCode: 'index',
    // 基础数据
    basicData: {
      // 常规表情
      commonEmojis: []
    },
    // 用户数据
    userData: {
      // 个人中心标题
      ucTitle: '',
      // 收藏的表情
      favoriteEmojis: []
    },
    // 可视化窗口数据
    viewport: {
      width: null,
      height: null
    },
    // 是否打开登录窗口
    visibleLoginDialog: false
  },
  mutations: {
    // 设置用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    // 选择菜单
    selectMenu (state, menuCode) {
      state.menuCode = menuCode
    },
    // 设置常规表情数据
    setCommonEmojis (state, emojis) {
      state.basicData.commonEmojis = emojis
    },
    // 设置用户收藏的表情数据
    setFavoriteEmojis (state, emojis) {
      state.userData.favoriteEmojis = emojis
    },
    // 添加收藏表情
    addFavoriteEmojis (state, emoji) {
      state.userData.favoriteEmojis.push(emoji)
    },
    // 置顶表情
    topFavoriteEmoji (state, emojiId) {
      const index = state.userData.favoriteEmojis.findIndex(item => item.id === emojiId)
      if (index === -1) {
        return
      }
      const emoji = state.userData.favoriteEmojis.splice(index, 1)
      state.userData.favoriteEmojis.unshift(emoji[0])
    },
    // 设置可视化窗口数据
    setViewport (state, viewport) {
      Object.assign(state.viewport, viewport)
    },
    // 设置登录窗口状态
    setVisibleLoginDialog (state, value) {
      state.visibleLoginDialog = value
    },
    // 设置个人中心标题
    setUcTitle (state, value) {
      state.userData.ucTitle = value
    }
  },
  actions: {
    // 获取登录用户信息
    async fetchLoginUserInfo ({ commit }) {
      await fetchLoginUserInfo()
        .then(data => {
          commit('setUserInfo', data)
        })
        .catch(() => {})
    },
    // 获取常规表情数据
    fetchEmojiList ({ commit, state }) {
      if (state.basicData.commonEmojis.length > 0) {
        return
      }
      fetchEmojiList()
        .then(data => {
          commit('setCommonEmojis', data)
        })
        .catch()
    },
    // 获取用户收藏的数据
    fetchFavoriteEmojiList ({ commit, state }) {
      if (state.userData.favoriteEmojis.length > 0) {
        return
      }
      fetchFavoriteEmojiList({
        page: 1,
        capacity: 200,
        model: {},
        sorts: []
      })
        .then(data => {
          commit('setFavoriteEmojis', data.records)
        })
        .catch(() => {})
    }
  },
  modules: {
  }
})
