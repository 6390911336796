<template>
  <a class="pipa-link" :href="to" :target="target">
    <Icon v-if="isOuterLink" type="el-icon-link"/>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    href: {
      type: String,
      required: true
    },
    target: {
      default: '_blank'
    }
  },
  computed: {
    to () {
      if (this.isOuterLink) {
        return `${window.location.origin}/link?target=${this.href}`
      }
      return this.href
    },
    isOuterLink () {
      return !this.href.startsWith(window.location.origin)
    }
  }
}
</script>

<style scoped lang="scss">
.pipa-link {
  i {
    font-size: 15px;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
}
</style>
