import request from '@/utils/request'

// 查询阅读题详情
export function fetchQuestionDetail (code) {
  return request.get(`/question/${code}`)
}

// 查询连炮问题
export function fetchContinueQuestionList (data) {
  return request.post('/question/continues', data)
}

// 查询阅读题列表
export function fetchQuestionList (data) {
  return request.post('/question/list/page', data)
}
