<template>
  <header class="pipa-header">
    <div class="content-wrap">
      <div class="left">
        <img src="../../../assets/images/logo.png">
        <ul>
          <li :class="{ selected: menuCode === 'index' }"><router-link to="/">阅读题库</router-link></li>
          <li :class="{ selected: menuCode === 'practice' }"><router-link to="/practice/setting">专项练习</router-link></li>
          <li :class="{ selected: menuCode === 'interview' }"><router-link to="/appoint/interview">技术评估</router-link></li>
          <li :class="{ selected: menuCode === 'pipa' }"><router-link to="/pipa">pipa圈</router-link></li>
        </ul>
      </div>
      <div class="right">
        <!-- 用户信息 -->
        <el-popover
          v-if="userInfo != null"
          popper-class="user-popper"
          placement="bottom"
          width="270"
          :offset="43"
          trigger="click"
        >
          <div>
            <div class="user-wrap">
              <img class="avatar" :src="userInfo.avatar">
              <p>{{userInfo.nickname}}</p>
              <UserMedals/>
            </div>
            <ul class="menu-items">
              <li>
                <router-link :to="{ name: 'UserAppoint' }">
                  <Icon type="icon-interview"/>
                  <span>我的面试</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'UserPipa' }">
                  <Icon type="icon-pipa2"/>
                  <span>pipa圈</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'UserFavorites' }">
                  <Icon type="icon-shoucang"/>
                  <span>我的收藏</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'UserFault' }">
                  <Icon type="icon-close"/>
                  <span>我的错题</span>
                </router-link>
              </li>
            </ul>
          </div>
          <ul slot="reference">
            <li class="message">
              <router-link to="/members"><Icon type="icon-xiaoxizhongxin"/></router-link><span>(0)</span>
            </li>
            <li class="user">
              <label>{{userInfo.nickname}}</label>
              <img :src="userInfo.avatar">
            </li>
            <li class="user-medals-wrap">
              <UserMedals/>
            </li>
          </ul>
          <div class="opera">
            <Button type="text" theme="info" :round="false" size="mini">ADJUSTRD个人中心</Button>
            <Button type="solid" theme="function" :round="false" @click="toPipaUC">前往枇杷个人中心</Button>
          </div>
        </el-popover>
        <!-- 登录/注册 -->
        <div v-else class="buttons">
          <Button size="big" type="outline" theme="function" @click="toLogin">登录</Button>
          <Button size="big" type="solid" theme="function" @click="toLogin">注册</Button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import UserMedals from '@/components/common/usr/UserMedals'
import BaseUser from '@/components/base/BaseUser'
export default {
  name: 'PipaHeader',
  extends: BaseUser,
  components: { UserMedals },
  computed: {
    ...mapState(['menuCode'])
  },
  methods: {
    // 前往枇杷个人中心
    toPipaUC () {
      window.location.href = '/usr/profile'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
header {
  height: 56px;
  line-height: 56px;
  box-shadow: $background-shadow;
  background-color: $background-color;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  .content-wrap {
    width: $page-width;
    height: 100%;
    display: flex;
    margin: 0 auto;
    .left {
      flex-shrink: 0;
      width: 700px;
      height: 100%;
      display: flex;
      overflow: hidden;
      img{
        width: 120px;
        animation: dynamicLogo 10s infinite;
      }
      // 菜单
      ul {
        flex-grow: 1;
        list-style: none;
        display: flex;
        font-size: 16px;
        margin: 0;
        padding: 0;
        li {
          padding: 0 30px;
          &.selected {
            font-size: 20px;
            border-bottom: 2px solid $primary-color;
          }
        }
        img {
          width: 20px;
          margin-right: 5px;
          position: relative;
          top: -1px;
        }
        img, span {
          vertical-align: middle;
        }
        span {
          position: relative;
          top: -2px;
        }
      }
    }
    .right {
      height: 100%;
      flex-grow: 1;
      /deep/ .el-popover__reference {
        cursor: pointer;
      }
      // 登录/注册
      .buttons {
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: right;
        // 消息
        .message {
          margin-right: 20px;
          i {
            margin-right: 5px;
            font-size: 18px;
            vertical-align: middle;
            color: $font-color-third;
          }
          span {
            color: $font-color-third;
            vertical-align: middle;
            position: relative;
            top: -1px;
          }
        }
        // 用户
        .user {
          label {
            display: inline-block;
            font-size: 14px;
            vertical-align: middle;
            max-width: 100px;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }
          img {
            margin: 0 10px;
            width: 30px;
            border-radius: 50%;
            vertical-align: middle;
          }
        }
        // 勋章
        .user-medals-wrap {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @keyframes dynamicLogo {
    0%{
      transform: rotateX(0) translateX(-200px);
    }
    10% {
      transform: rotateX(1440deg) translateX(20px);
    }
    12% {
      transform: translateX(0);
    }
    15% {
      transform: skewX(-30deg);
    }
    16% {
      transform: skewX(30deg);
    }
    17% {
      transform: skewX(-30deg);
    }
    18% {
      transform: skewX(30deg);
    }
    19% {
      transform: skewX(-30deg);
    }
    20% {
      transform: skewX(30deg);
    }
    21% {
      transform: skewX(-30deg);
    }
    22% {
      transform: skewX(30deg);
    }
    23% {
      transform: skewX(0) rotateX(0deg);
    }
    80% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(1440deg) translateX(-120px);
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/style/variables";
.user-popper {
  border-radius: 20px !important;
  .user-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .menu-items {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 49%;
      // background-color: $background-color-second;
      border-radius: 10px;
      height: 100px;
      margin-top: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease .15s;
      &:hover {
        background-color: $background-color-second;
        a {
          color: $font-color;
          .icon-pipa2 {
            color: $primary-color;
          }
          .icon-close {
            color: $font-color-red;
          }
          .icon-shoucang {
            color: $primary-color;
          }
        }
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $font-color-third;
        .icon {
          transition: all ease .15s;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }
  }
  .opera {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
