import Vue from 'vue'
import VueRouter from 'vue-router'
// 布局
import DefaultLayout from '../layouts/DefaultLayout'
import PersonalCenterLayout from '../layouts/PersonalCenterLayout'
import SecondLayout from '../layouts/SecondLayout'
// 首页
import Index from '../views/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/usr/autologin',
    name: 'AutoLogin',
    component: () => import('../views/usr/autologin')
  },
  {
    path: '/invite/practice/:practiceNo',
    name: 'PracticeInvite',
    component: () => import('@/views/question/practice/invite'),
    meta: {
      title: '专项练习好友邀请'
    }
  },
  {
    path: '/link',
    name: 'Link',
    component: () => import('@/views/link'),
    meta: {
      title: '跳转提示'
    }
  },
  // 默认布局
  {
    path: '/',
    name: 'DefaultLayout',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
          title: '枇杷村，一个温馨的IT社区',
          isFullTitle: true,
          menuCode: 'index'
        }
      },
      {
        path: '/question/:code',
        name: 'QuestionDetail',
        component: () => import('@/views/question/question/detail')
      },
      {
        path: '/appoint/interview',
        name: 'AppointInterview',
        component: () => import('@/views/appoint/interview'),
        meta: {
          title: '技术评估',
          menuCode: 'interview'
        }
      },
      {
        path: '/appoint/interview/success',
        name: 'AppointInterviewSuccessful',
        component: () => import('@/views/appoint/interview-successful')
      },
      {
        path: '/practice/setting',
        name: 'PracticeSetting',
        component: () => import('@/views/question/practice/setting'),
        meta: {
          title: '专项练习设置',
          menuCode: 'practice'
        }
      },
      {
        path: '/practice/:practiceNo',
        name: 'Practice',
        component: () => import('@/views/question/practice'),
        meta: {
          title: '专项练习'
        }
      },
      {
        path: '/practice/:practiceNo/report',
        name: 'PracticeReport',
        component: () => import('@/views/question/practice/report'),
        meta: {
          title: '练习报告'
        }
      },
      {
        path: '/plus',
        name: 'Plus',
        component: () => import('@/views/plus'),
        meta: {
          title: '开通Plus会员'
        }
      }
      // {
      //   path: '/pipa',
      //   name: 'Pipa',
      //   component: () => import('@/views/pipa'),
      //   meta: {
      //     title: 'pipa圈，程序员的朋友圈',
      //     isFullTitle: true,
      //     menuCode: 'pipa'
      //   }
      // }
    ]
  },
  // 个人中心布局
  {
    path: '/PersonalCenterLayout',
    name: 'PersonalCenterLayout',
    component: PersonalCenterLayout,
    meta: {
      needLogin: true
    },
    children: [
      {
        path: '/usr/fault',
        name: 'UserFault',
        meta: {
          title: '错题集'
        },
        component: () => import('@/views/usr/question/fault')
      },
      {
        path: '/usr/favorites',
        name: 'UserFavorites',
        meta: {
          title: '我的收藏'
        },
        component: () => import('@/views/usr/question/favorites')
      },
      {
        path: '/usr/practice',
        name: 'UserPractice',
        meta: {
          title: '个人资料'
        },
        component: () => import('@/views/usr/question/practice')
      },
      {
        path: '/usr/medal',
        name: 'UserMedals',
        meta: {
          title: '我的勋章'
        },
        component: () => import('@/views/usr/medals')
      },
      {
        path: '/usr/profile',
        name: 'UserProfile',
        meta: {
          title: '个人资料'
        },
        component: () => import('@/views/usr/profile')
      },
      {
        path: '/usr/news',
        name: 'UserNews',
        meta: {
          title: '我的消息'
        },
        component: () => import('@/views/usr/news')
      },
      {
        path: '/usr/pipa',
        name: 'UserPipa',
        meta: {
          title: '我的pipa圈'
        },
        component: () => import('@/views/usr/pipa')
      },
      {
        path: '/usr/appoint',
        name: 'UserAppoint',
        meta: {
          title: '我的预约'
        },
        component: () => import('@/views/usr/appoint')
      }
    ]
  },
  // 无底部网页布局
  {
    path: '/',
    name: 'SecondLayout',
    component: SecondLayout,
    children: [
      {
        path: '/pipa',
        name: 'Pipa',
        component: () => import('@/views/pipa'),
        meta: {
          title: 'pipa圈，程序员的朋友圈',
          isFullTitle: true,
          menuCode: 'pipa'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // 未登录
  if (router.app.$options.store.state.userInfo == null) {
    await router.app.$options.store.dispatch('fetchLoginUserInfo')
  }
  // 未登录 & 需要登录 => 跳转至首页
  if (router.app.$options.store.state.userInfo == null) {
    // 必须登录的页面，跳转至首页
    if (to.matched[0].meta.needLogin === true) {
      next({ name: 'Index' })
      return
    }
  }
  // 标题处理
  if (to.meta.title) {
    document.title = to.meta.title
    if (to.meta.isFullTitle !== true) {
      document.title = '枇杷村' + document.title
    }
  }
  // 个人中心标题处理
  if (to.matched[0] != null && to.matched[0].name === 'PersonalCenterLayout') {
    router.app.$options.store.commit('setUcTitle', to.meta.title)
  }
  // 头部菜单处理
  if (to.meta.menuCode) {
    router.app.$options.store.commit('selectMenu', to.meta.menuCode)
  }
  next()
})

export default router
