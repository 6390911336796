import message from './message'
export default {
  autoReconnect: true,
  connecting: false,
  instance: null,
  // 初始化
  init () {
    this.connecting = true
    this.instance = new WebSocket(process.env.VUE_APP_WS_URL)
    this.instance.onopen = () => {
      this.autoReconnect = true
      this.connecting = false
      // 接收消息
      this.instance.onmessage = event => {
        const res = JSON.parse(event.data)
        if (res.data.direcitve === 'NEW_MESSAGE') {
          message.info("收到一条新消息")
        }
        // this.__handleWsMessage(res.data)
      }
    }
    // 关闭后自动重连
    this.instance.onclose = () => {
      if (this.autoReconnect) {
        this.init()
      }
    }
  },
  // 发送消息
  sendMessage (directive, data) {
    this.instance.send(JSON.stringify({
      directive, data
    }))
  }
}
