import request from '@/utils/request'

// 修改用户资料
export function updateUserData (data) {
  return request.post('/usr/update', data)
}

// 根据令牌登录
export function loginByToken (data) {
  return request.post('/usr/loginByToken', data)
}

// 获取登录用户信息
export function fetchLoginUserInfo () {
  return request.get('/usr/loginInfo', {
    autoLogin: false
  })
}

// 收藏表情
export function favoriteEmoji (emojiId) {
  return request.get('/usr/emoji/' + emojiId)
}

// 使用表情
export function useEmoji (emojiId) {
  return request.get('/usr/emoji/use/' + emojiId)
}

// 查询收藏表情列表
export function fetchFavoriteEmojiList (data) {
  return request.post('/usr/emoji/page', data, {
    autoLogin: false
  })
}

// 发布pipa
export function publishPipa (data) {
  return request.post('/usr/pipa/create', data)
}
