<template>
  <div class="default-layout">
    <PipaHeader/>
    <main>
      <div class="content-wrap">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import PipaHeader from '@/components/common/layouts/PipaHeader'
export default {
  name: 'SecondLayout',
  components: { PipaHeader }
}
</script>

<style scoped lang="scss">
@import "../assets/style/variables";
.default-layout {
  .content-wrap {
    width: $page-width;
    margin: 0 auto;
    // height: 100%;
  }
  // 主体
  main {
    margin-top: 62px;
    height: calc(100vh - 62px);
    overflow: auto;
  }
}
</style>
