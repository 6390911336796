<template>
  <el-button
    :round="round"
    :class="{['type__' + type]: true, ['size__' + size]: true, ['theme__' + theme]: true, ['status__disabled']: disabled}"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <Icon v-if="icon != null" :type="icon"/><span><slot></slot></span>
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    // 按钮类型，实心solid，边线outline, text文本
    type: {
      type: String
    },
    // 主题，function功能性，info信息类
    theme: {
      type: String,
      default: 'none'
    },
    // 正常normal，大型big，小型mini
    size: {
      default: 'normal'
    },
    // 是否为圆形
    round: {
      default: true
    },
    // 图标
    icon: {
      type: String
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.el-button {
  background-color: transparent;
  * {
    vertical-align: middle;
  }
  i {
    padding: 0;
  }
}
// 类型
.type__solid {
  border: 1px solid transparent;
}
.type__outline {
  background-color: transparent;
  border: 1px solid transparent;
}
.type__text {
  border: 1px solid transparent;
}
// 主题
.theme__function {
  &.type__solid {
    background-color: $primary-color;
    color: #fff;
    &:hover {
      background-color: $primary-color + 10;
    }
  }
  &.type__outline {
    border-color: $primary-color;
    color: $primary-color;
    &:hover {
      background-color: $primary-color-hover;
    }
  }
  &.type__text {
    color: #333;
    &:hover {
      color: $primary-color;
    }
  }
}
.theme__info {
  &.type__solid {
    background-color: $background-color-second;
    color: $font-color-third;
    &:hover {
      color: $font-color;
      background-color: $background-color-second - 10;
    }
  }
  &.type__outline {
    border-color: $background-color-second;
    color: $font-color-third;
    &:hover {
      color: $font-color;
      background-color: $background-color-second + 10;
    }
  }
  &.type__text {
    color: $font-color-third;
    &:hover {
      color: $font-color;
    }
  }
}
// 尺寸
.size__big {
  padding-left: 30px;
  padding-right: 30px;
}
.size__small {
  padding-left: 15px;
  padding-right: 15px;
}
.size__mini {
  padding: 3px 8px;
}
// 状态
.status__disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
