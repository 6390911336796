function padding2 (str) {
  return (str + '').padStart(2, '0')
}
export default {
  install (Vue) {
    // 秒文本
    Vue.filter('secondsText', value => {
      if (value == null || value === '') {
        return '-'
      }
      const seconds = value % 60
      const minutes = Math.floor(value % (60 * 60) / 60)
      const hours = Math.floor(value / (60 * 60))
      let result = ''
      if (hours !== 0) {
        result += (hours + '小时')
      }
      if (minutes !== 0) {
        if (seconds === 0) {
          result += (minutes + '分钟')
        } else {
          result += (minutes + '分')
        }
      }
      if (seconds !== 0) {
        result += (seconds + '秒')
      }
      return result
    })
    // 岗位等级
    Vue.filter('positionLevelText', value => {
      if (value === '1') {
        return '初级'
      }
      if (value === '2') {
        return '中级'
      }
      if (value === '3') {
        return '高级'
      }
      return ''
    })
    // 时间转换
    Vue.filter('timeText', (value) => {
      const targetDate = new Date(value)
      const now = new Date()
      const timestamp = now.getTime() - targetDate.getTime()
      // 刚才（十分钟内）
      if (timestamp < 10 * 60 * 1000) {
        return '刚才'
      }
      // 分钟提示
      const minutes = Math.floor(timestamp / 60000)
      if (minutes < 60) {
        return `${minutes}分钟前`
      }
      // 今天（仅展示时间）
      if (targetDate.getFullYear() === now.getFullYear() && targetDate.getMonth() === now.getMonth() && targetDate.getDate() === now.getDate()) {
        return `${padding2(targetDate.getHours())}:${padding2(targetDate.getMinutes())}`
      }
      // 今天之前
      return `${targetDate.getFullYear()}/${padding2(targetDate.getMonth() + 1)}/${padding2(targetDate.getDate())}/ ${padding2(targetDate.getHours())}:${padding2(targetDate.getMinutes())}`
    })
  }
}
