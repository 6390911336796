<script>
export default {
  name: 'BaseComponent',
  data () {
    return {
      // 静态资源路径
      resourcePrefix: process.env.VUE_APP_API_PREFIX,
      // 视频前缀
      videoPrefix: '/api/resource/video?f=',
      // 图片前缀
      imagePrefix: '/api/resource/image?f='
    }
  }
}
</script>
