<template>
  <el-dialog
    :visible="visibleLoginDialog"
    :show-close="false"
    width="500px"
    custom-class="login-dialog"
    @close="handleClose"
  >
    <iframe :src="getInnerLoginUrl()" frameborder="0"></iframe>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import BaseUser from '@/components/base/BaseUser'

export default {
  name: 'LoginDialog',
  extends: BaseUser,
  computed: {
    ...mapState(['visibleLoginDialog'])
  },
  methods: {
    ...mapMutations(['setVisibleLoginDialog']),
    handleClose () {
      this.setVisibleLoginDialog(false)
    }
  }
}
</script>

<style lang="scss">
.login-dialog {
  overflow: hidden;
  border-radius: 10px !important;
  .el-dialog__header {
    display: none;
  }
  iframe {
    width: 100%;
    height: 385px;
  }
}
</style>
