<template>
  <div v-if="ads.length > 0" class="ad">
    <!-- 三图模式 -->
    <ul v-if="mode === 'three'" class="three">
      <li v-for="ad in ads" :key="ad.id">
        <a :href="ad.targetUrl" target="_blank">
          <el-image
            :style="{ height }"
            fit="contain"
            :src="ad.fileUri.startsWith('http') ? ad.fileUri : '/api' + ad.fileUri"
            :alt="ad.title"
          >
            <div slot="placeholder" :style="{ height }" class="image-slot">加载中...</div>
          </el-image>
        </a>
      </li>
    </ul>
    <!-- 单图模式 -->
    <el-carousel
      v-if="mode === 'one'"
      class="one"
      :interval="5000"
      arrow="never"
      :style="{ height }"
    >
      <el-carousel-item v-for="ad in ads" :key="ad.id">
        <a :href="ad.targetUrl" target="_blank">
            <!-- <div slot="placeholder" :style="{ height }" class="image-slot"> 加载中...</div> -->
          <el-image
            :style="{ height }"
            fit="contain"
            :src="ad.fileUri.startsWith('http') ? ad.fileUri : '/api' + ad.fileUri"
            :alt="ad.title"
          >
            <div slot="placeholder" :style="{ height }" class="image-slot"> 加载中...</div>
          </el-image>
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { fetchAdvertList } from '@/api/basic'

export default {
  name: 'Ad',
  props: {
    // tree三图模式，one单图模式
    mode: {
      type: String,
      required: true
    },
    // 广告组
    group: {
      type: String,
      required: true
    },
    // 高度
    height: {
      default: '100%'
    }
  },
  data () {
    return {
      ads: []
    }
  },
  methods: {
    init () {
      fetchAdvertList(this.group)
        .then(data => {
          this.ads = data
        })
        .catch(() => {})
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.ad {
  // 三图模式
  .three {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    li {
      width: 32%;
      .el-image {
        width: 100%;
      }
    }
  }
  // 单图模式
  .one {
    /deep/ .el-carousel__container {
      height: 100%;
    }
    .el-image {
      width: 100%;
    }
  }
  // 加载图片
  /deep/ .image-slot {
    width: 100%;
    background: url(../../assets/images/logo.png) center no-repeat;
    background-size: 100%;
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      filter: blur(30px);
      z-index: 2;
      background: inherit;
    }
  }
}
</style>
