import axios from 'axios'
import store from '@/store/index'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  timeout: 10 * 60 * 1000
})
// 添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 未登录
  if (response.data.code === 6100) {
    if (response.config.autoLogin === false) {
      return Promise.reject(response.data)
    }
    store.commit('setVisibleLoginDialog', true)
    return Promise.reject(new Error('#ignore#'))
  }
  if (response.data.code !== 200) {
    return Promise.reject(response.data)
  }
  return response.data.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default axiosInstance
