import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'
import '@wangeditor/editor/dist/css/style.css'
import components from './components/index'
import filters from './filters'
import plugins from './plugins'

Vue.use(ElementUI, {
  size: 'small'
})
Vue.use(components)
Vue.use(filters)
Vue.use(plugins)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
