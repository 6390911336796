<template>
  <div class="empty-tip" :style="{ height }">
    <div class="wrap">
      <img src="../../assets/images/pipa-empty.png">
      <p :style="{ color }">{{tip}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyTip',
  props: {
    // 提示文字
    tip: {
      type: String,
      default: '这里没有数据哦~'
    },
    // 提示文字颜色
    color: {
      type: String,
      default: '#999'
    },
    // 高度
    height: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style scoped lang="scss">
.empty-tip {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-items: center;
  align-items: center;
  .wrap {
    width: 260px;
    margin: 0 auto;
    text-align: center;
    img {
      width: 80px;
    }
    p {
      margin: 0;
    }
    .opera {
      margin-top: 30px;
    }
  }
}
</style>
