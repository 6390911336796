import consts from './consts'
import cache from './cache'
import message from './message'
import moment from 'moment'
import websocket from './websocket'
export default {
  install (Vue) {
    // 常量
    Vue.prototype.$consts = consts
    // 缓存对象
    Vue.prototype.$cache = cache
    // 消息提示
    Vue.prototype.$tip = message
    // 日期格式化
    Vue.prototype.$moment = moment
    // ws
    Vue.prototype.$ws = websocket
    websocket.init()
  }
}
