<template>
  <ul class="user-medals">
    <li><img src="../../../assets/images/medal-boss1.png"></li>
    <li><img src="../../../assets/images/medal-boss2.png"></li>
    <li><img src="../../../assets/images/medal-boss3.png"></li>
    <li class="medal-wish"><img src="../../../assets/images/medal-wish.png" alt=""></li>
  </ul>
</template>

<script>
export default {
  name: 'UserMedals'
}
</script>

<style scoped lang="scss">
.user-medals {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .medal-wish img{
    width: 15px;
  }
  img {
    width: 20px;
  }
}
</style>
