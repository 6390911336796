<template>
  <Button
    class="sort-button"
    :type="sort == null ? 'solid' : 'outline'"
    :theme="sort == null ? 'info' : 'function'"
    round
    @click="handleClick"
  >
    <slot></slot>
    <Icon type="el-icon-sort-down" :class="{selected: sort === 'desc'}"></Icon>
    <Icon type="el-icon-sort-up" :class="{selected: sort === 'asc'}"></Icon>
  </Button>
</template>

<script>
export default {
  name: 'SortButton',
  props: {
    sort: {
      type: String
    }
  },
  methods: {
    handleClick () {
      if (this.sort == null) {
        this.$emit('sort', 'desc')
        return
      }
      if (this.sort === 'desc') {
        this.$emit('sort', 'asc')
        return
      }
      if (this.sort === 'asc') {
        this.$emit('sort', null)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.sort-button {
  padding-right: 28px !important;
  position: relative;
  span {
    vertical-align: middle;
    .reverse {
      transform: rotate(180deg);
    }
  }
  &:hover {
    i {
      color: $font-color;
      &.selected {
        color: $primary-color;
      }
    }
  }
  i {
    position: absolute;
    top: 8px;
    font-size: 14px;
    font-weight: bold;
    color: $font-color-third;
    &.selected {
      color: $primary-color;
    }
    &:first-of-type {
      right: 14px;
    }
    &:last-of-type {
      right: 8px;
    }
  }
}
</style>
