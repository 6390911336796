/**
 * 常量定义
 */
export default {
  // 标语
  SLOGAN: '枇杷村，轻松学习，快乐工作，500万程序员都会玩儿',
  SLOGAN_SHORT: '枇杷村，轻松学习，快乐工作！',
  // 岗位等级
  POSITION_LEVELS: [
    { value: 1, name: '初级' },
    { value: 2, name: '中级' },
    { value: 3, name: '高级' }
  ]
}
