<template>
  <el-popover
    v-if="tipContent != null"
    :title="tipTitle"
    :width="tipWidth"
    trigger="click"
    :content="tipContent"
  >
    <i slot="reference" class="icon" :class="{pipafont: !type.startsWith('el-'), [type]: true, ['size__' + size]: true}"></i>
  </el-popover>
  <i v-else class="icon" :class="{pipafont: !type.startsWith('el-'), [type]: true, ['size__' + size]: true}"></i>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    // 类型
    type: {
      type: String,
      required: true
    },
    // 尺寸
    size: {
      type: String,
      default: 'mini'
    },
    // 提示标题
    tipTitle: {
      type: String
    },
    // 提示内容
    tipContent: {
      type: String
    },
    // 提示宽度
    tipWidth: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
$font-minisize: 16px;
$font-smallsize: 22px;
$font-bigsize: 30px;
.icon {
  vertical-align: middle;
  &.size__mini {
    font-size: $font-minisize;
    &.icon-dianzan {
      font-size: $font-minisize - 1;
      transition: all ease .4s;
      color: inherit;
    }
  }
  &.size__small {
    font-size: $font-smallsize;
    &.icon-dianzan {
      font-size: $font-smallsize - 1;
    }
  }
  &.size__big {
    font-size: $font-bigsize;
    &.icon-dianzan {
      font-size: $font-bigsize - 1;
    }
  }
  // 女图标
  &.icon-nv {
    color: hotpink;
  }
}
</style>

<style lang="scss">
.icon-popper {
  border: 1px solid #eee !important;
}
.el-popover__reference-wrapper .icon {
  cursor: pointer;
}
</style>
