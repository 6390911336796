<template>
  <div class="personal-center">
    <div class="head">
      <PipaHeader/>
    </div>
    <div class="main">
      <div class="menubar">
        <ul>
          <li><img class="avatar" src="../assets/images/avatar.png"></li>
          <li><router-link to="/members">开通Plus会员</router-link></li>
          <li :class="{selected: currentChecked == 'profile'}" @click="handleChecked('profile')"><router-link to="/usr/profile">个人资料</router-link></li>
          <li :class="{selected: currentChecked == 'pipa'}" @click="handleChecked('pipa')">
            <router-link to="pipa">pipa圈</router-link>
          </li>
          <li :class="{selected: currentChecked == 'fault'}" @click="handleChecked('fault')"><router-link to="/usr/fault">我的错题</router-link></li>
          <li :class="{selected: currentChecked == 'appoint'}" @click="handleChecked('appoint')">
            <router-link to="appoint">我的面试</router-link>
          </li>
          <li :class="{selected: currentChecked == 'favorites'}" @click="handleChecked('favorites')"><router-link to="/usr/favorites">我的收藏</router-link></li>
          <li :class="{selected: currentChecked == 'news'}" @click="handleChecked('news')"><router-link to="/usr/news">我的消息</router-link></li>
          <li :class="{selected: currentChecked == 'medal'}" @click="handleChecked('medal')"><router-link to="/usr/medal">我的奖章</router-link></li>
        </ul>
      </div>
      <div class="content-wrap">
        <div class="modify">
          <h3>{{userData.ucTitle}}</h3>
          <div class="change-data">
          </div>
        </div>
        <div class="content">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PipaHeader from '@/components/common/layouts/PipaHeader'
export default {
  name: 'PersonalCenterLayout',
  components: { PipaHeader },
  computed: {
    ...mapState(['userData'])
  },
  data () {
    return {
      currentChecked: 'profile'
    }
  },
  methods: {
    handleChecked (type) {
      this.currentChecked = type
    }
  },
  mounted () {
    this.currentChecked = this.$route.path.split('/').slice('-1')?.[0] || 'profile'
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/variables";
.personal-center{
  .main{
    display: flex;
    width: $page-width;
    margin: 76px auto 0;
    .menubar{
      flex-shrink: 0;
      width: 200px;
      margin-right: 10px;
      &:hover {
        .avatar {
          transition: all ease .3s;
          opacity: 1;
        }
      }
      ul{
        margin: 0;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        li{
          font-size: 16px;
          margin-bottom: 15px;
          color: $font-color-third;
          &.selected {
            a {
              color: $primary-color;
              &:hover {
                color: $primary-color;
              }
            }
          }
          //开会员
          &:nth-of-type(2){
            width: 90px;
            height: 25px;
            font-size: 12px;
            background-color: $primary-color;
            border-radius: 45px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            a{
              color: #ffffff;
            }
            a:hover{
              color: $font-color;
            }
          }
          //两个上边框线
          &:nth-of-type(3),&:nth-of-type(5){
            width: 200px;
            text-align: center;
            border-top: 1px solid #eee;
            padding-top: 15px;
          }
          a {
            color: $font-color-third;
            &:hover {
              color: $font-color;
            }
          }
        }
      }
      img.avatar{
        transition: all ease .3s;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        opacity: .6;
      }
    }
    .content-wrap{
      border-radius: 15px;
      flex-grow: 1;
      background: #fff;
      //标题个人资料
      h3{
        flex-shrink: 0;
        border-bottom: 1px solid #eee;
        margin: 0 0 20px 0;
        padding: 10px 20px;
        font-weight: normal;
        font-size: 14px;
        color: $font-color-third;
      }
    }
  }
}
</style>
