<template>
  <div id="app">
    <router-view/>
    <LoginDialog/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import LoginDialog from '@/components/common/LoginDialog'
export default {
  name: 'App',
  components: { LoginDialog },
  computed: {
    ...mapState(['visibleLoginDialog'])
  },
  methods: {
    ...mapMutations(['setViewport', 'setVisibleLoginDialog']),
    ...mapActions(['fetchLoginUserInfo'])
  },
  mounted () {
    this.setViewport({
      width: document.body.offsetWidth,
      height: document.body.offsetHeight
    })
    window.addEventListener('resize', () => {
      this.setViewport({
        width: document.body.offsetWidth,
        height: document.body.offsetHeight
      })
    })
    // iframe打开特殊处理
    if (!window.isIframe) {
      // 消息处理
      window.addEventListener('message', e => {
        if (e.data == null) {
          return
        }
        // 登录成功（由登录窗口iframe发送）
        if (e.data.directive === 'loginSuccess') {
          // 关闭窗口
          this.setVisibleLoginDialog(false)
          // 更新登录信息
          this.fetchLoginUserInfo()
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/style/app";
@import "./assets/style/iconfont";
</style>
