// 防抖
export const debounce = (fn, delay = 600) => {
  let timeout = null
  return function (e) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

// 节流
export const throttle = (fn, delay = 600) => {
  let lastTime = 0
  return function (e) {
    const currentTime = new Date()
    if (currentTime - lastTime >= delay) {
      fn.apply(this, arguments)
      lastTime = currentTime
    }
  }
}
