import Button from './common/Button'
import Icon from './common/Icon'
import Pagination from './common/Pagination'
import Loading from './common/Loading'
import EmptyTip from './common/EmptyTip'
import Link from './common/Link'

export default {
  install (Vue) {
    Vue.component('Button', Button)
    Vue.component('Icon', Icon)
    Vue.component('Pagination', Pagination)
    Vue.component('Loading', Loading)
    Vue.component('EmptyTip', EmptyTip)
    Vue.component('Link', Link)
  }
}
