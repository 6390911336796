import request from '@/utils/request'

// 查询常规表情列表
export function fetchEmojiList () {
  return request.get('/basic/emoji/common')
}

// 查询岗位列表
export function fetchPositionList (data) {
  return request.post('/basic/position/list', data)
}

// 查询广告列表
export function fetchAdvertList (group) {
  return request.get(`/basic/advert/list/${group}`)
}
